<template>
  <div class="card">
    <div class="card-body">
      <div class="row success">
        <div class="col-xl-8">
          <h5 style="color: blue; margin-bottom: 30px">
            <span style="margin-right: 30px"> {{ $t("bulletin.notes") }}</span>
            <a-tooltip
              placement="right"
              v-if="selectedClasse && selectedTrimester"
            >
              <template slot="title">
                {{
                  isValidatedBulletin()
                    ? $t("bulletin.hideBulletin")
                    : $t("bulletin.validateBulletin")
                }}
              </template>
              <span>
                <a-button
                  :type="isValidatedBulletin() ? 'primary' : 'danger'"
                  @click="confirmeBulletinValidation()"
                >
                  {{
                    isValidatedBulletin()
                      ? $t("bulletin.cancelValidation")
                      : $t("bulletin.validatedClassroom")
                  }}
                </a-button>
              </span>
            </a-tooltip>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <label>{{ $t("bulletin.listeClasses") }}</label>
          <a-select
            show-search
            :placeholder="$t('emploi.listeClasses')"
            option-filter-prop="children"
            :filter-option="filterOption"
            style="width: 150px; margin-left: 15px"
            @change="
              (val) => {
                handleClassChange('classe', val);
              }
            "
          >
            <a-select-option value="all"> --- </a-select-option>
            <a-select-option
              v-for="classe in classes"
              :key="classe._id"
              :value="classe._id"
            >
              {{ classe.name }}
            </a-select-option>
          </a-select>

          <label style="margin-right: 5px; margin-left: 15px">{{
            this.$t("scolarite.listeModules")
          }}</label>
          <a-select
            :placeholder="$t('scolarite.listeModules')"
            style="width: 180px; margin-left: 15px"
            :loading="moduleLoading"
            v-model="selectedModule"
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            @change="
              (val) => {
                this.selectedModule = val;

                this.activeModule = this.listModules.find(
                  (module) => module._id == val
                );

                this.selectedModuleName = this.activeModule.name;

                handleModuleChange(val);
              }
            "
          >
            <a-select-option
              v-for="module in listModules"
              :key="module._id"
              :value="module._id"
            >
              {{ module.name }}
            </a-select-option>
          </a-select>

          <label style="margin-right: 5px; margin-left: 15px">{{
            this.$t("emploi.trimestre")
          }}</label>
          <a-select
            :placeholder="$t('paiement.selectionnerMois')"
            style="width: 50px; margin-left: 15px"
            @change="
              (val) => {
                this.selectedTrimester = val;
                this.handleClassChange('classe', this.selectedClasse);
                if (this.selectedModule) updateMarks();
              }
            "
            default-value="1"
          >
            <a-select-option
              v-for="trimester in ['1', '2', '3']"
              :key="trimester"
              :value="trimester"
            >
              {{ trimester }}
            </a-select-option>
          </a-select>

          <a-dropdown style="margin-left: 15px" v-if="selectedClasse">
            <template #overlay>
              <a-menu
                @click="
                  (val) => {
                    if (val.key == '1') printTable();
                    else if (val.key == '2') printTable(true);
                  }
                "
              >
                <a-menu-item key="1">{{
                  $t("bulletin.imprimerFichModuleVide")
                }}</a-menu-item>
                <a-menu-item key="2">{{
                  $t("bulletin.imprimerFichModule")
                }}</a-menu-item>
              </a-menu>
            </template>
            <a-button
              type="primary"
              :loading="printLoading"
              :disabled="printLoading"
            >
              <a-icon v-if="!printLoading" type="file-pdf" />
              {{ $t("action.imprimer") }}
            </a-button>
          </a-dropdown>

          <br />
          <br />
          <div class="card" v-if="selectedModule && selectedClasse">
            <a-table
              :columns="columns"
              :data-source="activeData"
              :loading="tableLoading"
              :scroll="{ x: 'max-content' }"
            >
              <template
                v-for="subject in subjectList"
                :slot="subject._id"
                slot-scope="text, record"
              >
                <div :key="subject._id">
                  <div>
                    <div
                      v-for="item in subject.subsubjectData"
                      :key="item._id"
                      class="d-flex"
                      :class="
                        getMarkValue(item._id, record._id, true) < 0 ||
                        getMarkValue(item._id, record._id, true) > item.maxMark
                          ? 'danger'
                          : ''
                      "
                    >
                      <div>
                        <span class="ant-input-wrapper">
                          <span
                            class="ant-input-group-addon"
                            style="min-width: 175px !important"
                            >{{ item.name }}</span
                          >
                          <a-input-number
                            :value="getMarkValue(item._id, record._id, true)"
                            style="
                              height: 35px;
                              width: 65px;
                              text-align: center;
                            "
                            @change="
                              (val) => {
                                handleMarkChange(
                                  val,
                                  subject._id,
                                  item._id,
                                  record._id,
                                  true
                                );
                              }
                            "
                          ></a-input-number>
                          <span class="ant-input-group-addon"
                            >/{{ item.maxMark }}</span
                          >
                        </span>

                        <a-popconfirm
                          :title="$t('all.sureToDelete')"
                          @confirm="
                            () => deleteMark(item._id, record._id, true)
                          "
                        >
                          <a-button
                            tabindex="-1"
                            type="dashed"
                            shape="circle"
                            class="ml-2"
                            icon="delete"
                          ></a-button>
                        </a-popconfirm>
                      </div>
                    </div>
                    <span v-if="subject.divided" class="ant-input-wrapper">
                      <a-input-number
                        :value="getTotalSubsubjects(record, subject._id)"
                        :disabled="true"
                        style="
                          height: 35px;
                          width: 65px;
                          margin-left: 175px;
                          text-align: center;
                          background-color: #f0f2f4;
                        "
                      ></a-input-number>
                      <span class="ant-input-group-addon">/20</span>
                    </span>
                    <div
                      v-else
                      :class="
                        getMarkValue(subject._id, record._id, false) < 0 ||
                        getMarkValue(subject._id, record._id, false) > 20
                          ? 'danger'
                          : ''
                      "
                    >
                      <span class="ant-input-wrapper">
                        <a-input-number
                          :value="getMarkValue(subject._id, record._id, false)"
                          style="height: 35px; width: 65px; text-align: center"
                          @change="
                            (val) => {
                              handleMarkChange(
                                val,
                                subject._id,
                                null,
                                record._id,
                                false
                              );
                            }
                          "
                        ></a-input-number>
                        <span class="ant-input-group-addon">/20</span>
                      </span>

                      <a-popconfirm
                        :title="$t('all.sureToDelete')"
                        @confirm="
                          () => deleteMark(subject._id, record._id, false)
                        "
                      >
                        <a-button
                          tabindex="-1"
                          type="dashed"
                          shape="circle"
                          class="ml-2"
                          icon="delete"
                        ></a-button>
                      </a-popconfirm>
                    </div>
                  </div>
                </div>
              </template>
            </a-table>
          </div>
          <div
            class="row"
            v-if="selectedClasse && selectedModule && activeData.length !== 0"
          >
            <div class="col-xl-7">{{ $t("bulletin.toutMatieres") }}</div>
            <div class="col-xl-3">
              <a-button
                :loading="saveLoading"
                style="
                  margin-right: 5px;
                  margin-left: 20px;
                  margin-bottom: 10px;
                  background: #8bc34a !important;
                  border-color: #8bc34a !important;
                "
                type="primary"
                @click="saveAll()"
              >
                {{ $t("bulletin.enregistrerTout") }}<a-icon type="check" />
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      @ok="
        () => {
          visibleBulletinDate = false;
        }
      "
      @cancel="
        () => {
          visibleBulletinDate = false;
        }
      "
      v-model="visibleBulletinDate"
      :title="$t('bulletin.date')"
      :closable="false"
      :width="500"
      :height="700"
      :key="'bulletinDate'"
    >
      <div>
        <label style="margin-right: 5px">{{ $t("bulletin.date") }}</label>
        <a-date-picker
          :allowClear="false"
          @change="
            (val) => {
              bulletinDate = val;
            }
          "
          :default-value="moment(new Date())"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
/*eslint-disable */
//ANCHOR class/month filter to this view: DONE

import apiClient from "@/services/axios";
import { mapState } from "vuex";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { notification, Modal } from "ant-design-vue";
import moment from "moment";

export default {
  computed: mapState(["settings", "user"]),
  components: {},

  destroyed() {
    window.removeEventListener("beforeunload", this.confirmLeaveReload);
  },
  async created() {
    window.addEventListener("beforeunload", this.confirmLeaveReload);
    this.tableLoading = true;

    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
            level: 1,
            bulletinVerySpec: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data));

    this.tableLoading = false;
    this.filtredTable = this.activeData;

    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];

    let { start, end } = this.settings.schoolarYears.find(
      (el) => el._id == this.settings.activeSchoolarYear
    );

    start = new Date(start).getFullYear();
    end = new Date(end).getFullYear();

    this.schoolarYearName = end + " / " + start;

    this.schoolType = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0].type;
    if (!this.schoolType)
      notification.warning({
        message: this.$t("bulletin.typeIntrouvable"),
        description: this.$t("bulletin.saisirSchoolType"),
      });

    // apiClient
    //   .get("/mark/classroomMarksStats/" + this.selectedTrimester + "/normal")
    //   .then(({ data }) => {
    //     this.classroomMarksStats = data;
    //   });
  },
  data() {
    return {
      visibleBulletinDate: false,
      bulletinDate: new Date(),
      updatedChanges: true,
      printLoading: false,
      syncLoading: false,
      syncSubjectLoading: false,
      visibleClassnameList: false,
      visibleSubjectList: false,
      schoolType: null,
      filtredTable: [],
      subjectList: [],
      classroomMarksStats: {},
      selectedModule: this.$t("scolarite.listeModules"),
      selectedModuleName: "",
      bulletins: { students: [] },
      selectedClassName: "",
      markData: [],
      originalMarkData: [],
      activeModule: null,
      bulletinData: [],
      certifParams: [],
      moduleLoading: false,
      selectedTrimester: "1",
      selectedClasse: null,
      tableLoading: false,
      saveLoading: false,
      activeData: [],
      markRefs: [],
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      rowData: [],
      data1: null,
      classes: [],
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [],
      subsubjectData: [],
      listModules: [],
      selectedLevel: null,
      bulletinColors: {
        color1: [70, 187, 239],
        color2: [64, 187, 239],
        color3: [16, 169, 229],
        color4: [67, 62, 63],
        color5: [52, 172, 215],
        color6: [224, 237, 243],
        color7: [64, 187, 239],
        color8: [225, 225, 226],
        color9: [224, 237, 243],
      },
      schoolDetails: {},
      schoolarYearName: "",
    };
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;

    if (this.updatedChanges) next();
    else
      this.$confirm({
        title: this.$t("all.unsavedchanges"),
        content: this.$t("all.unsavedchangesContent"),
        okText: this.$t("all.oui"),
        okType: "danger",
        cancelText: this.$t("action.annuler"),
        onOk() {
          _this.updatedChanges = true;
          next();
        },
        onCancel() {
          next(false);
        },
      });
  },
  methods: {
    moment,
    getSumMaxMArks(subsubjectData) {
      let sumMax = 0;
      subsubjectData.forEach((sub) => {
        sumMax += sub.maxMark;
      });
      return sumMax;
    },
    confirmLeaveReload(event) {
      if (!this.updatedChanges) {
        event.returnValue = this.$t("all.unsavedchangesContent");
      }
    },
    confirmeBulletinValidation() {
      const content = this.isValidatedBulletin()
        ? this.$t("bulletin.hideBulletin")
        : this.$t("bulletin.validateBulletin");
      this.$confirm({
        title: this.$t("all.sureTo"),
        content: (h) => <div style="color:red"> {content} </div>,
        onOk: () => {
          //console.log("OK");
          this.validateBulletin();
        },
        onCancel: () => {
          //console.log("Cancel");
        },
        class: "test",
      });
    },
    isValidatedBulletin() {
      if (this.selectedClasse) {
        const bulletin = this.classes.find(
          (item) => item._id === this.selectedClasse
        ).bulletinVerySpec || { sem1: false, sem2: false, sem3: false };
        if (this.selectedTrimester == 1 && bulletin.sem1 == true) return true;
        else if (this.selectedTrimester == 2 && bulletin.sem2 == true)
          return true;
        else if (this.selectedTrimester == 3 && bulletin.sem3 == true)
          return true;
      }
      return false;
    },
    validateBulletin() {
      const isValidated = this.isValidatedBulletin();
      const bulletin = this.classes.find(
        (item) => item._id === this.selectedClasse
      ).bulletinVerySpec || { sem1: false, sem2: false, sem3: false };
      if (this.selectedTrimester == 1) bulletin.sem1 = !bulletin.sem1;
      if (this.selectedTrimester == 2) bulletin.sem2 = !bulletin.sem2;
      if (this.selectedTrimester == 3) bulletin.sem3 = !bulletin.sem3;
      apiClient
        .patch("/classrooms/" + this.selectedClasse, {
          data: {
            bulletinVerySpec: bulletin,
          },
          bulletin: {
            classroom: this.selectedClasse,
            trimester: this.selectedTrimester,
            avaliable: !isValidated,
            type: "verySpecific",
          },
        })
        .then(() => {
          this.classes = this.classes.map((item) => {
            if (item._id === this.selectedClasse)
              item.bulletinVerySpec = bulletin;
            return item;
          });

          if (!isValidated)
            this.$message.success(
              this.$t("bulletin.validated", { name: this.selectedClassName })
            );
          else
            this.$message.success(
              this.$t("bulletin.unvalidated", { name: this.selectedClassName })
            );
        });
    },
    async sleep(timeout) {
      await new Promise((r) => setTimeout(r, timeout));
    },
    componentToHex(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    },

    rgbToHex(r, g, b) {
      return (
        "#" +
        this.componentToHex(r) +
        this.componentToHex(g) +
        this.componentToHex(b)
      );
    },
    async getModules(level) {
      this.moduleLoading = true;
      await apiClient
        .post("/moduleVerySpecific/filter", {
          query: {
            status: "active",
            level: level,
            trimester: this.selectedTrimester,
          },
        })
        .then((res) => {
          if (res.data.length == 0) {
            this.listModules = [];
            return this.$message.warning(this.$t("error.aucModule"));
          }

          this.listModules = res.data;

          this.listModules = this.listModules.map((module) => {
            module.subjects = module.subjects.map((subject) => {
              const subsubjects = module.subjectsInfo[subject._id];
              subject.divided = false;
              if (subsubjects && subsubjects.subsubjectsMark) {
                subject.divided = true;
                subject.subsubjectData = [];
                for (const key in subsubjects.subsubjectsMark) {
                  const s = module.subsubjects.find(
                    (s) => String(s._id) == String(key)
                  );

                  const subsubject = subsubjects.subsubjectsMark[key];
                  subject.subsubjectData.push({
                    name: s.name,
                    _id: s._id,
                    divided: true,
                    maxMark: subsubject,
                  });
                }
              }

              return subject;
            });
            return module;
          });
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
        })
        .finally(() => (this.moduleLoading = false));
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    async saveAll() {
      this.saveLoading = true;

      // check if subsubject marks are completed
      for (const subject of this.subjectList) {
        if (subject.divided) {
          const subsubjects = subject.subsubjectData.map((s) => String(s._id));
          for (const student of this.activeData) {
            const marks = this.markData.filter((m) => {
              return (
                m.student == student._id &&
                m.divided &&
                subsubjects.includes(m.subsubject)
              );
            });

            if (marks.length != 0 && marks.length != subsubjects.length) {
              this.saveLoading = false;
              return this.$message.warning(
                this.$t("warning.missedSubsubjects", { name: student.fullName })
              );
            }
          }
        }
      }

      // check if marks are correct
      let thereError = false;
      for (const mark of this.markData) {
        if (!mark.divided) {
          if (isNaN(Number(mark.mark)) || mark.mark > 20 || mark.mark < 0) {
            thereError = true;
            break;
          }
        } else if (mark.divided) {
          let subsubject;
          for (const subject of this.subjectList) {
            if (subject.divided) {
              subsubject = subject.subsubjectData.find(
                (s) => s._id == mark.subsubject
              );
              if (subsubject) break;
            }
          }
          if (subsubject)
            if (
              isNaN(Number(mark.mark)) ||
              mark.mark > subsubject.maxMark ||
              mark.mark < 0
            ) {
              thereError = true;
              break;
            }
        }
      }

      if (thereError) {
        this.$message.warning(this.$t("warning.notCorrectMarks"));
        this.saveLoading = false;
        return;
      }

      // saving
      const toAddData = [];

      for (const mark of this.markData) {
        const originalMark = this.originalMarkData.find(
          (m) =>
            m.student == mark.student &&
            ((!m.divided && m.subject == mark.subject) ||
              (m.divided && m.subsubject == mark.subsubject))
        );
        if (originalMark) {
          if (Number(mark.mark) != Number(originalMark.mark))
            await apiClient
              .patch("/markvspecific/" + originalMark._id, {
                data: {
                  mark: mark.mark,
                },
              })
              .then()
              .catch((err) => console.log(err));
        } else {
          const obj = {
            divided: mark.divided,
            student: mark.student,
            mark: mark.mark,
            trimester: this.selectedTrimester,
            classroom: this.selectedClasse,
            subject: mark.subject,
            type: "one",
          };
          if (mark.divided) obj.subsubject = mark.subsubject;
          toAddData.push(obj);
        }
      }

      await apiClient
        .put("/markvspecific/many", {
          data: toAddData,
        })
        .then()
        .catch((err) => console.log(err));

      for (const originalMark of this.originalMarkData) {
        const mark = this.markData.find(
          (m) =>
            m.student == originalMark.student &&
            ((!m.divided && m.subject == originalMark.subject) ||
              (m.divided && m.subsubject == originalMark.subsubject))
        );
        if (!mark)
          await apiClient
            .delete("/markvspecific/" + originalMark._id)
            .then()
            .catch((err) => console.log(err));
      }

      await this.updateMarks();

      this.updatedChanges = true;
      this.$message.success(this.$t("success.noteToutesEnregistre"));

      this.saveLoading = false;
    },

    deleteMark(id, studentId, divided) {
      this.markData = this.markData.filter(
        (m) =>
          !(
            m.student == studentId &&
            m.divided == divided &&
            (m.subsubject == id || m.subject == id)
          )
      );
      this.updatedChanges = false;
    },

    handleMarkChange(newMark, subjectId, subSubjectId, studentId, divided) {
      const mark = this.markData.find(
        (m) =>
          m.student == studentId &&
          ((divided && m.subsubject == subSubjectId) ||
            (!divided && m.subject == subjectId))
      );
      if (mark) mark.mark = Number(newMark);
      else {
        const obj = {
          student: studentId,
          mark: Number(newMark),
          divided: divided,
          subject: subjectId,
        };
        if (divided) obj.subsubject = subSubjectId;

        this.markData.push(obj);
      }

      this.updatedChanges = false;
    },

    getMarkValue(id, studentId, divided) {
      const mark = this.markData.find(
        (m) =>
          m.student == studentId &&
          m.divided == divided &&
          (m.subsubject == id || m.subject == id)
      );
      if (mark) return mark.mark;
      return null;
    },

    getTotalSubsubjects(record, subjectId) {
      const subject = this.subjectList.find((s) => s._id == subjectId);
      const subsubjects = subject.subsubjectData.map((s) => String(s._id));
      console.log(subsubjects, record);
      const marks = this.markData.filter((m) => {
        return (
          m.student == record._id &&
          m.divided &&
          subsubjects.includes(m.subsubject)
        );
      });

      let total = 0;
      marks.forEach((mark) => {
        total += mark.mark;
      });
      let sumMax = this.getSumMaxMArks(subject.subsubjectData);
      return total ? ((total * 20) / sumMax).toFixed(2) : "";
    },

    async handleModuleChange(val) {
      this.columns = [
        {
          title: this.$t("bulletin.nom"),
          dataIndex: "fullName",
          key: "fullName",
          scopedSlots: {
            customRender: "fullName",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.fullName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
      ];

      this.subjectList = [];

      this.activeModule.subjects.forEach((subject) => {
        this.subjectList.push(subject);
      });

      for (const subject of this.subjectList) {
        this.columns.push({
          title: subject.name,
          dataIndex: subject._id,
          scopedSlots: { customRender: subject._id },
        });
      }

      this.updateMarks();
    },

    async updateMarks() {
      this.tableLoading = true;
      await apiClient
        .post("/markvspecific/filter", {
          query: {
            classroom: this.selectedClasse,
            trimester: this.selectedTrimester,
            type: {
              $in: ["one"],
            },
          },
        })
        .then(({ data }) => {
          this.markData = [];
          this.originalMarkData = [];
          this.originalMarkData = data;
          data.map((mark) => {
            this.markData.push({
              student: mark.student,
              subject: mark.subject,
              subsubject: mark.subsubject,
              mark: mark.mark,
              divided: mark.divided,
            });
          });
        })
        .finally(() => (this.tableLoading = false));
    },

    async handleClassChange(on, value) {
      console.log(value);
      console.log(this.selectedTrimester);
      this.markRefs = [];
      this.selectedModule = "";
      this.moduleLoading = true;
      this.selectedClasse = value;
      this.selectedClassName = this.classes.find(
        (item) => item._id === value
      ).name;
      this.selectedLevel = this.classes.find(
        (item) => item._id === value
      ).level;
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] = value;
      await apiClient
        .post("/students/filter", {
          query: { status: "active" },
          aggregation: [
            {
              $match: match,
            },
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classroom",
              },
            },
            {
              $project: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                gender: 1,
                firstNameLower: { $toLower: "$firstName" },
                classroom: {
                  _id: 1,
                  name: 1,
                },
              },
            },
            {
              $sort: {
                firstNameLower: 1,
              },
            },
          ],
        })
        .then(({ data }) => {
          this.rowData = [];
          data.map((elem) => {
            try {
              this.rowData.push({
                _id: elem._id,
                key: elem._id,
                fullName: elem.firstName + " " + elem.lastName,
                classroom: elem.classroom[0].name,
                classroom_id: elem.classroom[0]._id,
                gender: elem.gender,
              });
            } catch {}
          });
          this.activeData = this.rowData;
          this.tableLoading = false;
          this.filtredTable = this.activeData;
        });
      //get modules
      this.getModules(this.selectedLevel);
      this.updateMarks();
    },

    async printTable(withGrades = false) {
      this.$gtag.event(withGrades ? "Imp fich remplie" : "Imp fich vide", {
        event_category: "Impression PDF",
        event_label: "Bulletin specifique-NotesModules section",
        value: 1,
      });

      this.printLoading = true;
      var doc = new JsPDF({
        orientation: "p",
        unit: "mm",
        format: "a3",
      });
      const pdf_width = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;

      let schoolDetails = this.user.building.find(
        (el) => el.dbName == this.settings.activeBuilding
      );
      let schoolName = schoolDetails.ArabicName
        ? schoolDetails.ArabicName
        : schoolDetails.name;
      let { start, end } = this.settings.schoolarYears.find(
        (el) => el._id == this.settings.activeSchoolarYear
      );

      start = new Date(start).getFullYear();
      end = new Date(end).getFullYear();

      //console.log(this.schoolarYearName);
      let schoolarYearName = end + " / " + start;

      let className = this.classes.find(
        (el) => el._id == this.selectedClasse
      ).name;
      let date = new Date();
      doc.setFontSize(10);
      doc.setFont("Amiri", "Bold");
      doc.setTextColor(140, 140, 140);
      let formattedDate = moment(date)
        .locale("ar-tn")
        .format("dddd، D MMMM، YYYY");
      doc.text(15, 15, formattedDate ? formattedDate : "");

      let width1 = doc.getTextWidth(`: المدرسة `);
      doc.text(pdf_width - width1 - 15, 15, ` : المدرسة `);
      let width2 = doc.getTextWidth(schoolName);
      doc.text(pdf_width - width1 - width2 - 15, 15, schoolName);

      width1 = doc.getTextWidth(`${schoolarYearName} : السنة الدراسية`);
      doc.text(
        pdf_width - width1 - 15,
        20,
        `${schoolarYearName} : السنة الدراسية`
      );

      width1 = doc.getTextWidth(`: القسم `);
      doc.text(pdf_width - width1 - 15, 25, ` : القسم `);
      width2 = doc.getTextWidth(className);

      doc.text(
        pdf_width - width1 - width2 - 15,
        25,
        className ? className : ""
      );

      let first = true;
      this.listModules.forEach((module) => {
        let maxRows = 1;
        const rowsTable = {};
        module.subjects.forEach((subject) => {
          if (subject.divided) {
            if (subject.subsubjectData.length > maxRows)
              maxRows = subject.subsubjectData.length;
          }
        });

        module.subjects.forEach((subject) => {
          rowsTable[subject._id] = [];
          if (!subject.divided) {
            rowsTable[subject._id].push(maxRows);
          } else {
            if (subject.subsubjectData.length < maxRows) {
              let nb = Math.floor(maxRows / subject.subsubjectData.length);
              let total = nb * subject.subsubjectData.length;
              subject.subsubjectData.forEach((subsubject) => {
                rowsTable[subject._id].push(nb);
              });
              rowsTable[subject._id][rowsTable[subject._id].length - 1] =
                maxRows - total + nb;
            } else {
              subject.subsubjectData.forEach((subsubject) => {
                rowsTable[subject._id].push(1);
              });
            }
          }
        });

        if (!first) doc.addPage();
        else first = false;
        doc.setFontSize(14);
        if (/[a-zA-Z]/.test(module.name)) {
          doc.setFont("helvetica", "bold");
        } else {
          doc.setFont("Noto Sans Arabic", "Bold");
        }
        let width = doc.getTextWidth(module.name);
        doc.text(module.name ? module.name : "", pdf_width / 2 - width / 2, 25);

        const array = [];

        this.activeData.forEach((student) => {
          for (let i = 0; i < maxRows; i++) {
            const data = [];
            module.subjects.forEach((subject) => {
              if (rowsTable[subject._id][i]) {
                if (subject.divided) {
                  const subsubject = subject.subsubjectData[i];
                  const mark =
                    this.getMarkValue(
                      subject.subsubjectData[i]._id,
                      student._id,
                      true
                    ) || "";
                  data.push({
                    content: withGrades
                      ? mark + ` :  (${subsubject.maxMark}/)` + subsubject.name
                      : `:  (${subsubject.maxMark}/)` + subsubject.name,
                    rowSpan: rowsTable[subject._id][i],
                  });
                } else {
                  const mark =
                    this.getMarkValue(subject._id, student._id, false) || "";
                  data.push({
                    content: withGrades ? mark : "",
                    rowSpan: rowsTable[subject._id][i],
                  });
                }
              }
            });
            if (i == 0)
              data.push({ content: student.fullName, rowSpan: maxRows });

            array.push(data);
          }
        });

        const columnStyles = {};
        for (let i = 0; i < module.subjects.length; i++) {
          columnStyles[i] = { cellWidth: 140 / module.subjects.length };
        }

        doc.autoTable({
          theme: "grid",
          styles: {
            fontSize: 9,
            font: "Amiri",
          },
          headStyles: {
            fontSize: 9,
            font: "Noto Sans Arabic",
            fontStyle: "Bold",
            lineColor: [204, 204, 204],
            lineWidth: 0.1,
          },
          bodyStyles: {
            fontSize: 9,
          },
          cellPadding: 0,
          columnStyles: columnStyles,
          body: array,
          head: [
            [
              ...module.subjects.map((s) => ({
                content: s.name,
                halign: "center",
              })),
              {
                content: "الإسم و اللقب",
                halign: "center",
                valign: "middle",
                font: "helvetica",
              },
            ],
          ],
          margin: { top: 35 },
          didParseCell: function (data) {
            if (data.section == "head") {
              if (/[a-zA-Z]/.test(data.cell.text[0])) {
                data.cell.styles.font = "helvetica";
                data.cell.styles.fontStyle = "bold";
              } else {
                data.cell.styles.font = "Noto Sans Arabic";
                data.cell.styles.fontStyle = "Bold";
              }
            }
            data.cell.styles.halign = "center";
            data.cell.styles.valign = "middle";
          },
        });
      });

      const pages = doc.internal.getNumberOfPages();

      doc.setFontSize(8);
      doc.setFont("Amiri", "Bold");

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pdf_width / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(` ${pages} / ${j} صفحة`, horizontalPos, verticalPos, {
          align: "center",
        });
      }

      this.printLoading = false;

      if (withGrades) doc.save(className + " - Fiche remplie specifique.pdf");
      else doc.save(className + " - Fiche vide specifique.pdf");
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped>
.success .ant-btn-danger {
  background: #8bc34a !important;
  border-color: #8bc34a !important;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.danger .ant-input-number {
  border-color: #f5222e !important;
}

.danger .ant-input-number-focused {
  border-color: #f5222e !important;
}

.ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group-addon {
  display: inline-block !important;
  height: 35px !important;
  width: 40px !important;
  padding-top: 5px !important;
  /* color: #a3ba99d2;*/
}
</style>
